import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigModel } from '../models/config.model';
import { lastValueFrom } from 'rxjs';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private _config: ConfigModel | undefined;

  readonly configPath: string = '';

  readonly http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
    this.configPath = this.getConfigPath();
  }

  async loadConfig(): Promise<void> {
    return lastValueFrom(this.http.get<ConfigModel>(this.configPath)).then(
      (settings) => {
        this._config = settings;
      }
    );
  }

  get config(): ConfigModel | undefined {
    return this._config;
  }

  getConfigPath(): string {
    const { stage } = env;

    const stages = new Map<string, string>([
      ['production', 'prod'],
      ['homologation', 'homolog']
    ]);

    const currentStage = stages.get(stage);

    if (!currentStage) {
      return `../../../assets/static/config.json`;
    }

    return `../../../assets/static/config.${currentStage}.json`;
  }
}
